@import url('reset.css');
@import url('animation.css');

.wrapper {
    max-width: 600px;
    width: calc(100% - 32px);
    margin: auto;
    padding: 16px 0px;
}

.m-auto {
    margin: auto;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.justify-space {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.absolute {
    position: absolute;
}

.left {
    left: 0px;
}

.right {
    right: 0px;
}

/* gabs */

.w-326 {
    width: 326px;
}

.w-130 {
    width: 130px;
}

.w-92 {
    width: 92px;
}

.w-32 {
    width: 32px;
}

.h-130 {
    height: 130px;
}

.h-92 {
    height: 92px;
}

.h-32 {
    height: 32px;
}

/* margins */

.mt-58 {
    margin-top: 58px;
}
.mt-auto {
    margin-top: auto;
}


.mt-40 {
    margin-top: 40px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 8px;
}

.mb-58 {
    margin-bottom: 58px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-16 {
    margin-bottom: 32px;
}

/* text */

h1 {
    color: #000;
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 75% */
    text-transform: uppercase;
}

h4 {
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

p {
    color: #BDBDBD;
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.t-color-alert {
    color: #e75d69;
}

/* other */

.bg-grey {
    background-color: #212121;
}

.pointer {
    cursor: pointer;
}

.border-16 {
    border-radius: 16px;
}

.border-black {
    border: 1px solid #000;
}

.btn-1 {
    border: none;
    outline: none;
    display: flex;
    width: 214px;
    height: 50px;
    padding: 24px 117px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
}

.btn-1 p {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    line-height: 24px;
}

.btn-2 {
    position: absolute;
    top: 72px;
    left: 72px;
}

.btn-3 {
    display: flex;
    width: 310px;
    height: 61px;
    padding: 24px 80px 24px 79px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: none;
}

.containForAnimation {
    position: relative;
    overflow: hidden;
    width: 266px;
    height: 24px;
    margin: auto;
    border-radius: 47px;
}

.result {
    position: relative;
    max-width: 358px;
    margin: auto;
}

.resultImageContainer {
    overflow: hidden;
    position: relative;
    border-radius: 100%;
    width: 358px;
    height: 358px;
    border: 2px solid #FFF;
}

.resultImageContainer img {
    width: 100%;
}

.result_name {
    background-size: cover;
    background-image: url('../../../public/imgs/name.png');
    
    color: #F2F2F2;
    text-align: center;
    font-size: 32px;
    width: 248px;
    min-width:248px;
    max-width: 248px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-height: 40px;
    min-height: 40px;
    display: block;
    width: fit-content;
    margin: auto;

    padding-top: 20px;
    padding-bottom: 11px;
    padding-left: 37px;
    padding-right: 37px;

    position: relative;
    z-index: 999;
    transform: translateY(-48px);
}
.fake-input{
    color: #828282;
    background-color: #212121;
    padding: 25px;
    text-align: left;
    border-radius: 8px;
    width: calc(100% - 48px);
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
}
.name-input{
    color: #828282;
    background-color: #212121;
    padding: 25px;
    text-align: left;
    border-radius: 8px;
    width: calc(100% - 64px);
    border: none;
    outline: none;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
}
.share-button{
    color: #000000;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    padding: 24px;
    text-align: center;
    display: flex;
  justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    width: calc(100% - 64px);
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    margin-top: 8px;
}
.result-bottom{
    display: flex;
    cursor: pointer;
    width: 100px;
}
.result-bottom > p{
    font-family: 'Montserrat', sans-serif;
    margin-left: 8px;
}

@media(max-width: 750px) {
    .color-chooser{
        display: block;
        text-align: center;
    }
    .back-pointer{
        top: 36px;
        left: 36px;
    }
}

@media (min-width: 414px) and (max-width: 1024px) {
    main {
        margin-top: auto;
    }
}

@media(max-width: 500px){
    .color-chooser{
        display: block;
        text-align: center;
    }
    .back-pointer{
        top: 36px;
        left: 36px;
    }

    .m-mt-auto {
        margin-top: auto;
    }
    .m-mt-70 {
        margin-top: 70px;
    }
    .m-mt-24 {
        margin-top: 24px;
    }
    .m-mb-24 {
        margin-bottom: 24px;
    }
    .m-mb-16 {
        margin-bottom: 16px;
    }
    .m-padding-0 {
        padding: 0px;
    }

    h1 {
        font-size: 29px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        text-transform: uppercase;
        margin-top: 70px;
    }

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    p br {
        display: none;
    }
}

.rslt-btns {
    width: 358px;
    height: 50px;
}

@media (max-width: 390px) {
    .result {
        width: 100%;
    }
    .resultImageContainer {
        width: 308px;
        height: 308px;
        margin: auto;
    }
}

@media (max-width: 385px) {
    .m-w-100p {
        width: 100%;
    }
    .m-w-82 {
        width: 82px;
    }
    .m-h-82 {
        height: 82px;
    }
    .m-fs-12 {
        font-size: 12px;
    }

    
}
@media (max-width: 375px) {
    .rslt-btns {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .resultImageContainer {
        width: 280px;
        height: 280px;
        margin: auto;
    }
    .result_name {
        font-size: 28px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
