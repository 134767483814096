.hBallContainer {
    width: 360px;
    height: 360px;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
}

@media (max-width: 385px) {
    .hBallContainer {
        width: 300px;
        height: 300px;
        margin-top: 70px;
    }
}

#hball {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: rotateAnim 5s infinite;
    animation-timing-function: linear;
}

#line {
    position: absolute;
    top: 3px;
    left: -200px;
    animation: lineAnim 8s ease infinite;
    animation-timing-function: linear;
}

@keyframes rotateAnim {
    0% {
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
    25% {
        transform: translate(-50%, -50%) rotate(270deg) scale(1.6);
    }
    50% {
        transform: translate(-50%, -50%) rotate(180deg) scale(2);
    }
    75% {
        transform: translate(-50%, -50%) rotate(90deg) scale(1.6);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
}

@keyframes lineAnim {
    from {left: -200px;}
    to {left: 200px;}
}